
import Section1 from "@/views/main/views/user/navigation/list.nav";

const navigation = [
    {
        header: 'USERS'
    },
    Section1,
];

export default navigation;