export default {
  data() {
    return {};
  },
  computed: {
    isDarkSkin() {
      return this.$store.getters["appConfig/skin"] === "dark";
    },
    bgTabsNavs() {
      //getters
      return this.$store.getters["appConfig/skin"] === "dark"
        ? "nav-dark-tabs"
        : "nav-light-tabs";
    },
  },
  methods: {},
};
