import Vue from "vue";
import moment from "moment";

Vue.filter("capitalize", function (sentence) {
  if (!sentence) return "";
  sentence = sentence.toString();
  return sentence
    .toLowerCase()
    .split(" ")
    .map(function (word) {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(" ");
});

Vue.filter("globalDate", function (date) {
  if (date) {
    return moment(String(date)).format("DD-MM-YYYY");
  }
});

Vue.filter('limitChars', function(value, limit) {
  if (value.length > limit) {
    return value.slice(0, limit) + '...';
  }
  return value;
});